import React from 'react';
import moment from 'moment';
import { Spin } from 'antd';
import './index.less';

const NoData = Loader.NoData;
const IconFont = Loader.loadBaseComponent('IconFont');

class PictureTools extends React.Component {
  constructor() {
    super();
    this.cantainerRef = React.createRef();
    this.canvasRef = React.createRef();
    this.sourceData = null;
    this.imageSource = null;
    this.scaleTimer = null;

    this.drawRectOptions = {
      isOpen: false,
      isStart: false,
      isChange: false
    };
    this.dragOptions = {
      isStart: false
    };
    this.state = {
      isLoaded: false,
      loadError: false,
      showMenu: false,
      menuPosition: {
        x: 0,
        y: 0
      }
    };
    this.options = {
      scale: 1,
      rotate: 0,
      x: 0,
      y: 0,
      scaleTemp: {
        x: 0,
        y: 0
      },
      selectArea: null
    };
    this.position = {
      start: { x: 0, y: 0 },
      end: { x: 0, y: 0 },
      data: { x: 0, y: 0 }
    };
    this.computedMenuSize();
  }

  componentDidMount() {
    const { onInit, catchError } = this.props;
    const canvas = this.getCanvas();
    onInit && onInit(this);
    this.reloadPic().catch(err => {
      catchError && catchError();
      this.setState({ loadError: true });
    });
    canvas.addEventListener('resize', this.reloadPic, false);
  }
  componentWillUnmount() {
    const canvas = this.getCanvas();
    canvas.removeEventListener('resize', this.reloadPic, false);
    this.cantainerRef = null;
    this.canvasRef = null;
    this.sourceData = null;
    this.imageSource = null;
    this.scaleTimer = null;
    this.drawRectOptions = null;
    this.dragOptions = null;
    this.options = null;
    this.position = null;
    this.menus = null;
    this.menuSize = null;
  }
  computedMenuSize() {
    this.menus = [
      { name: 'faceLibrary', text: '搜人脸', icon: 'icon-S_Bar_Face' },
      { name: 'bodyLibrary', text: '搜人体', icon: 'icon-M_AID_Body' },
      { name: 'vehicleLibrary', text: '搜机动车', icon: 'icon-S_Bar_Motor' },
      { name: 'nonVehicleLibrary', text: '搜非机动车', icon: 'icon-S_Bar_NonMotor' }
    ].filter(v => !!BaseStore.menu.getInfoByName(v.name));
    this.menuSize = {
      w: 105,
      h: this.menus.length * 38
    };
  }
  reloadPic = async () => {
    const { imagePath, rect, imageType } = this.props;
    this.setState({ isLoaded: false });
    this.imageSource = await Utils.loadImage(imagePath, true);
    this.sourceData = this.imageSource.cloneNode();
    if (rect) {
      this.sourceDataFaceReact = await this.loadFaceRectImage(this.sourceData, rect, imageType);
    } else {
      this.sourceDataFaceReact = this.sourceData;
    }
    const canvas = this.getCanvas();
    const { width, height } = canvas.getBoundingClientRect();
    this.sourceData.width = canvas.width = width;
    this.sourceData.height = canvas.height = height;
    this.drawSourceData();
    this.setState({ isLoaded: true });
    canvas.addEventListener('mousedown', this.mouseDown, false);
  };
  loadFaceRectImage = async (image, rect, imageType) => {
    let frame = rect.split(',');
    if (frame.length === 0) {
      return image;
    }
    let x, y, w, h;
    switch (imageType) {
      case 'body':
        x = frame[0] - frame[2] * 0.2;
        y = frame[1] - frame[3] * 0.2;
        w = frame[2] * 1.4;
        h = frame[3] * 1.4;
        break;
      case 'vehicle':
        x = frame[0];
        y = frame[1];
        w = frame[2];
        h = frame[3];
        break;
      default:
        // face
        x = frame[0] - frame[2] * 0.8;
        y = frame[1] - frame[3] * 1.5;
        w = frame[2] * 2.6;
        h = frame[3] * 3.2;
        break;
    }

    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 3;
    ctx.strokeRect(x, y, w, h);
    let url = canvas.toDataURL();
    setTimeout(() => {
      canvas = null;
      ctx = null;
    }, 500);
    return Utils.loadImage(url, true);
  };
  // 滚轮事件
  handleWheel = e => {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
      this.scale(e.deltaY / 1000);
    }
  };
  bindEvent(flag) {
    const method = flag ? 'addEventListener' : 'removeEventListener';
    document[method]('mousemove', this.mouseMove, false);
    document[method]('mouseup', this.mouseUp, false);
  }

  startScreenshot = () => {
    this.drawRectOptions.isOpen = true;
    this.drawSourceData();
  };
  cancelScreenshot = () => {
    this.drawRectOptions.isOpen = false;
    this.options.selectArea = null;
    this.state.showMenu && this.setState({ showMenu: false });
    this.drawSourceData();
  };
  getSelectResult = () => {
    const { scale, selectArea } = this.options;
    if (!selectArea) {
      return;
    }

    const [x, y, w, h] = selectArea;
    const canvas = this.getCanvas();
    const { width, height } = this.imageSource;
    const sourceSize = {
      width,
      height,
      wScale: width / canvas.width,
      hScale: height / canvas.height
    };
    const area = [x * sourceSize.wScale, y * sourceSize.hScale, w * sourceSize.wScale, h * sourceSize.hScale].map(v => Math.round(v));
    let fullCanvas = document.createElement('canvas');
    let fullCtx = fullCanvas.getContext('2d');
    
    fullCanvas.width = width;
    fullCanvas.height = height;
    fullCtx.save();
    // document.body.appendChild(fullCanvas)
    fullCtx.setTransform(scale, 0, 0, scale, this.options.x* sourceSize.wScale, this.options.y* sourceSize.hScale);
    fullCtx.translate(width / 2, height / 2);
    fullCtx.rotate((this.options.rotate * Math.PI) / 180);
    fullCtx.drawImage(this.imageSource,-width / 2, -height / 2, width, height);
    fullCtx.restore();
    let imageData = fullCtx.getImageData(...area);
    
    let tempCanvas = document.createElement('canvas');
    let tempCtx = tempCanvas.getContext('2d');
    tempCanvas.width = area[2];
    tempCanvas.height = area[3];
    // document.body.appendChild(tempCanvas)
    tempCtx.putImageData(imageData, 0, 0);
    const dataUrl = tempCanvas.toDataURL();
    setTimeout(() => {
      fullCanvas.remove();
      tempCanvas.remove();
      fullCanvas = null;
      tempCanvas = null;
      tempCtx = null;
      fullCtx = null;
      imageData = null;
    }, 100);
    return dataUrl;
  };
  /**
   * 结束绘制
   */
  mouseUp = event => {
    this.position.end = {
      x: event.clientX,
      y: event.clientY
    };
    if (this.drawRectOptions.isOpen) {
      this.drawRectOptions.isStart = false;
      if (this.drawRectOptions.isChange) {
        let position = this.computedMenuPosition();
        this.setState({ showMenu: true, menuPosition: position });
        this.drawRectOptions.isChange = false;
      }
    } else {
      this.dragOptions.isStart = false;
      this.position.data = {
        x: this.position.end.x - this.position.data.x,
        y: this.position.end.y - this.position.data.y
      };
      this.dragCallback();
    }
    this.bindEvent(false);
  };

  computedMenuPosition() {
    const domRect = this.getCanvas().getBoundingClientRect();
    let x, y;
    if (this.position.start.x > this.position.end.x) {
      x = this.position.end.x - this.menuSize.w - domRect.x;
      y = this.position.end.y - this.menuSize.h - domRect.y;
      if (x < 0) {
        x = 0;
      }
      if (y < 0) {
        y = 0;
      }
    } else {
      x = this.position.end.x - domRect.x;
      y = this.position.end.y - domRect.y;
      if (domRect.width - x < this.menuSize.w) {
        x = domRect.width - this.menuSize.w;
      }
      if (domRect.height - y < this.menuSize.h) {
        y = domRect.height - this.menuSize.h;
      }
    }

    return {
      x,
      y
    };
  }

  dragCallback = () => {
    const { scale } = this.options;
    if (scale === 1) {
      this.options.x = 0;
      this.options.y = 0;
      this.drawSourceData();
      return;
    }
    const domRect = this.getCanvas().getBoundingClientRect();
    const w = domRect.width;
    const h = domRect.height;
    const lx = w * (scale - 1);
    const ly = h * (scale - 1);
    let x, y;
    x = this.options.x;
    y = this.options.y;
    if (x > 0) {
      x = 0;
    }
    if (x < -lx) {
      x = -lx;
    }
    if (y > 0) {
      y = 0;
    }
    if (y < -ly) {
      y = -ly;
    }
    if (x !== this.options.x || y !== this.options.y) {
      this.options.x = x;
      this.options.y = y;
      this.drawSourceData();
    }
  };

  /**
   * 开启绘制禁区
   * @param {Event} event
   */
  mouseDown = event => {
    const { allowDrag = true } = this.props;
    this.position.start = {
      x: event.clientX,
      y: event.clientY
    };
    this.position.data = {
      x: event.clientX,
      y: event.clientY
    };
    if (this.drawRectOptions.isOpen) {
      this.dragOptions.isStart = false;
      this.drawRectOptions.isStart = true;
    } else {
      if (!allowDrag) {
        return;
      }
      this.drawRectOptions.isOpen = false;
      this.dragOptions.isStart = true;
    }
    this.bindEvent(true);
  };
  /**
   * 用于绘制禁区储存坐标
   * @param {Event} event
   */
  mouseMove = event => {
    if (this.drawRectOptions.isStart) {
      //TODO 绘制模式
      this.position.end = {
        x: event.clientX,
        y: event.clientY
      };
      this.drawSelectRact({ position: this.position });
      this.drawRectOptions.isChange = true;
      this.state.showMenu && this.setState({ showMenu: false });
    } else {
      //TODO 拖拽模式
      const { clientX, clientY } = event;

      this.position.end = {
        x: clientX,
        y: clientY
      };

      const x = this.position.end.x - this.position.start.x;
      const y = this.position.end.y - this.position.start.y;
      this.options.x += x;
      this.options.y += y;
      this.drawSourceData();
      this.position.start = this.position.end;
    }
  };

  /**
   * 绘制所有对象
   * @param {*} isNewArea
   */
  drawSourceData() {
    const canvas = this.getCanvas();
    const ctx = this.getContext();
    const { x, y, scale } = this.options;
    const { width, height } = canvas.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.setTransform(scale, 0, 0, scale, x, y);
    if (typeof this.options.rotate === 'number') {
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((this.options.rotate * Math.PI) / 180);
      ctx.drawImage(this.drawRectOptions.isOpen ? this.sourceData : this.sourceDataFaceReact, -canvas.width / 2, -canvas.height / 2, canvas.width, canvas.height);
      ctx.restore();
    } else {
      ctx.drawImage(this.drawRectOptions.isOpen ? this.sourceData : this.sourceDataFaceReact, 0, 0, canvas.width, canvas.height);
    }

    if (this.options.selectArea) {
      ctx.resetTransform();
      let area = this.options.selectArea;
      ctx.beginPath();
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      ctx.strokeRect(...area);
    }
  }
  getCanvas = () => {
    return this.canvasRef.current;
  };
  getContext() {
    return this.getCanvas().getContext('2d');
  }
  rotate(num) {
    this.options.rotate = parseInt(this.options.rotate + num);
    this.drawSourceData();
    this.forceUpdate();
  }
  scale(num) {
    let flag = false;
    if (this.options.scale + num > 3) {
      this.options.scale = 3;
      flag = true;
    }
    if (this.options.scale + num < 1) {
      this.options.scale = 1;
      flag = true;
    }
    if (!flag) {
      this.options.scale = parseFloat((this.options.scale + num).toFixed(2));
      this.drawScale(this.options.scale, num > 0);
    }
  }
  resetScale() {
    this.options.scale = 1;
    this.options.x = 0;
    this.options.y = 0;
    this.drawSourceData();
    this.props.changeScale && this.props.changeScale(this.options.scale);
  }
  resetRotate() {
    this.options.rotate = 0;
    this.drawSourceData();
  }
  drawScale() {
    const canvas = this.getCanvas();
    const lx = (this.options.scale - 1) * canvas.width;
    const ly = (this.options.scale - 1) * canvas.height;
    this.options.x = lx / -2;
    this.options.y = ly / -2;
    this.drawSourceData();
    this.props.changeScale && this.props.changeScale(this.options.scale);
  }
  drawSelectRact = () => {
    const canvas = this.getCanvas();
    const domRect = canvas.getBoundingClientRect();
    const x = this.position.start.x - domRect.left + 0;
    const y = this.position.start.y - domRect.top + 0;
    const { width, height } = domRect;
    let w = this.position.end.x - this.position.start.x;
    let h = this.position.end.y - this.position.start.y;
    if (w + x > width) {
      w = width - x;
    }
    if (h + y > height) {
      h = height - y;
    }
    this.options.selectArea = [x, y, w, h];

    this.drawSourceData(true);
    this.props.rectChange && this.props.rectChange({ x, y, w, h });
  };
  jumpPage = name => {
    const { beforeJumppage, captureTime = Date.now(), imagePath } = this.props;
    let id = Utils.uuid();
    let url = this.getSelectResult();
    if (Utils.isFullscreen(this.cantainerRef.current)) {
      Utils.exitFullscreen();
    }
    const searchData = {
      startTime: moment(captureTime)
        .subtract(72, 'hours')
        .valueOf(),
      endTime: moment(captureTime)
        .add(72, 'hours')
        .valueOf(),
      timerTabsActive: 2
    };
    LM_DB.add('parameter', { id, url, frameUrl: url, searchData }).then(() => {
      beforeJumppage && beforeJumppage();
      BaseStore.tab.goPage({ moduleName: name, data: { id, isSearch: true, searchType: 1 } });
    });
  };
  render() {
    const { className = '' } = this.props;
    const { isLoaded, loadError, showMenu, menuPosition } = this.state;
    if (loadError) {
      return <NoData />;
    }

    return (
      <Spin spinning={!isLoaded} wrapperClassName="picture-canvas-loading">
        <div className={`picture-canvas ${className}`} ref={this.cantainerRef}>
          <canvas ref={this.canvasRef} onWheel={this.handleWheel} />
          {this.props.children}
          <div className="menu-action-group" style={{ display: showMenu ? 'block' : 'none', left: menuPosition.x, top: menuPosition.y }}>
            {this.menus.map(v => (
              <div className="menu-action-item" onClick={() => this.jumpPage(v.name)}>
                <IconFont type={v.icon} />
                {v.text}
              </div>
            ))}
          </div>
        </div>
      </Spin>
    );
  }
}

export default PictureTools;
